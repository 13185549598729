<template>
  <div class="app-wrapper" :class="[store.getters.sidebarOpened ? 'openSidebar' : 'hideSidebar']">
    <!-- 左侧菜单栏 -->
    <Sidebar class="sidebar-container" :style="{backgroundColor: variables.menuBg}"/>
    <!-- 中间内容 -->
    <div class="main-container">
      <!--  顶部   -->
      <div class="fixed-header">
        <nav-bar/>
      </div>
      <app-main/>
    </div>
  </div>
</template>

<script setup>
import Sidebar from './components/Sidebar'
import AppMain from "@/layout/components/AppMain.vue";
import NavBar from "@/layout/components/NavBar.vue";
import variables from '@/styles/variables.scss'
import {useStore} from "vuex";

const store = useStore()
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width .2s;
}
.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
</style>
