import {createStore} from "vuex";
import user from './user'
import getters from './getters'
import app from "@/store/app";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: {
    user,
    app
  }
})
