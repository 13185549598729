<template>
  <div class="">
    <div class="logo-container">
      <img :size="sidebarHeight" shape="circle" src="./1.png" />
    </div>
    <el-scrollbar>
      <SidebarMenu />
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from "@/layout/components/Sidebar/SidebarMenu.vue";

const sidebarHeight = 45
</script>

<style lang="scss" scoped>
.logo-container {
  height: v-bind(sidebarHeight)+'px';
  padding: 15px 0 22px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
}
</style>
