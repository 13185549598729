<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <template v-for="(item, index) in breadcrumbData" :key="index">
        <el-breadcrumb-item v-if="index === breadcrumbData.length -1">
          <span class="no-redirect">{{item.meta.title}}</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else :to="{ path: item.path }">{{item.meta.title}}</el-breadcrumb-item>
      </template>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>

import {useRoute} from "vue-router";
import {ref, watch} from "vue";
import {useStore} from "vuex";

const route = useRoute()

const breadcrumbData = ref([])

watch(route, () => {
  breadcrumbData.value = route.matched.filter(item => item.meta && item.meta.title)
}, {
  immediate: true
})

const store = useStore()
const linkHoverColor = ref(store.getters.cssVar.menuBg)

</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 9px;

  .redirect {
    color: #666;
    cursor: pointer;
    font-weight: 600;
  }
  .redirect:hover {
    color: v-bind(linkHoverColor);
  }

  :deep(.no-redirect) {
    color: #97a8be;
    cursor: text;
  }
}
</style>
