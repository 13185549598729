import {createRouter, createWebHashHistory} from "vue-router";
import layout from '@/layout'
import ArticleCreaterRouter from './modules/ArticleCreate'
import ArticleRouter from './modules/Article'
import PermissionListRouter from './modules/PermissionList'
import RoleListRouter from './modules/RoleList'
import UserManageRouter from './modules/UserManage'
import store from '@/store'

/*
* 公开路由表
* */
const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    redirect: '/profile',
    component: layout,
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile'),
        meta: {
          title: '品牌列表',
          icon: 'el-icon-user',
          elIcon: 'User'
        }
      },
      // {
      //   path: '/orderList',
      //   name: 'orderList',
      //   component: () => import('@/views/profile/orderList'),
      //   meta: {
      //     title: '证书列表',
      //     icon: 'el-icon-user',
      //     elIcon: 'User'
      //   }
      // },
      {
        path: '/orderList',
        name: 'orderList',
        component: () => import('@/views/userInfo/index'),
        meta: {
          title: '证书列表',
          icon: 'el-icon-user',
          elIcon: 'User'
        }
      },
      {
        path: '/articlelist',
        name: 'articlelist',
        component: () => import('@/views/articleCreate/index'),
        meta: {
          title: '资讯列表',
          icon: 'el-icon-user',
          elIcon: 'User'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/errorPage/404.vue')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/errorPage/401.vue')
      }
    ]
  }
]

// 权限路由表
const privateRoutes = [
  // UserManageRouter,
  // RoleListRouter,
  // PermissionListRouter,
  // ArticleCreaterRouter,
  // ArticleRouter
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...publicRoutes,
    ...privateRoutes
  ]
})

export default router
