<template>
  <router-view />
</template>

<script setup>

</script>

<style scoped>

</style>