import {createApp} from "vue"
import App from "./App.vue"
import store from "@/store"
import router from "@/router"
import elementPlus from '@/plugins/element'
import icons from "@/icons";

// 导入全局样式文件
import '@/styles/index.scss'

// import './permission'

// 生成全局实例对象
const app = createApp(App)

// 挂载插件及组件
icons(app)
elementPlus(app)
app.use(store).use(router).mount('#app')
