<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.app-container {
  min-height: calc(100% - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 61px 20px 20px 20px;
  box-sizing: border-box;
}
</style>
