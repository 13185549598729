import {getItem, setItem} from "@/utils/storage";

// 过期时间
const tokenTimeOut = 2 * 60 * 60 * 1000

// 获取时间
export function getTimeStamp() {
  return getItem('timeStamp')
}

// 设置时间
export function setTimeStamp() {
  setItem('timeStamp', Date.now())
}

// 是否超时
export function isCheckTimeout() {
  const currentTime = Date.now()
  return currentTime - getTimeStamp() > tokenTimeOut
}
