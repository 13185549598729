// 是否为外部资源
export function isExternal(val) {
  return /^(http?:|mailto:|tel:)/.test(val)
}

// 判断值是否为空
export function isNullOrUndefined(node) {
  if (node instanceof String) {
    node = node.replace(/(^\s*)|(\s*$)/g, '')
  }
  return (
    node === undefined || node == null || node === '' || node.length === 0
  )
}
