import {getUserInfo, gologin,logout} from "@/api/sys";
import md5 from "md5";
import {getItem, removeAllItem, setItem} from "@/utils/storage";
import router from "@/router";
import {setTimeStamp} from "@/utils/auth";

export default ({
  namespaced: true,
  state: () => ({
    token: getItem('token') || '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem('token', token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    // 登录
    onLogin(context,userInfo) {
      console.log(userInfo)
      const {email, password} = userInfo
      return new Promise((resolve) => {
        gologin({
          email,
          password
        }).then(res => {
          console.log(res)
          // this.commit('user/setToken', res.access_token)
          router.push('/profile')
          resolve()
        })
      })
    },
    // 获取用户信息
    async getUserInfo(context, userInfo) {
      const res = await getUserInfo()
      this.commit('user/setUserInfo', res)
      return res
    },
    // 退出登录
    logout() {
      logout()
      .then(res=>{
        router.push('/login')
      })
      // this.commit('user/setToken', '')
      // this.commit('user/setUserInfo', {})
      // removeAllItem()
      
    }
  }
})
