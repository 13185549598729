import axios from "axios"
import md5 from "md5";
import { ElMessage } from "element-plus";
import store from "@/store";
import { isCheckTimeout } from "@/utils/auth";
import router from "@/router";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use((config) => {
  const { icode, time } = getTestICode()
  config.headers.icode = icode
  config.headers.codeType = time
  // 在这个位置需要统一的去注入token
  if (store.getters.token) {
    // 如果token存在 注入token
    // if (isCheckTimeout()) {
    //   // 登出操作
    //   store.dispatch('user/logout')
    //   return Promise.reject(new Error('token 失效'))
    // }
    config.headers.Authorization = `Bearer ${store.state.user.token}`
  }
  // 配置接口国际化
  config.headers['Accept-Language'] = store.getters.language
  return config // 必须返回配置
},
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use((response) => {
  
  const { status, statusText, data } = response
  //   要根据success的成功与否决定下面的操作
  if (status == 200) {
    // 成功返回解析后的数据
    return response.data
  } else {
    // 业务错误
    console.log(response)
    ElMessage.error(statusText) // 提示错误消息
    return Promise.reject(new Error(statusText))
    router.push('/login')
    // store.dispatch('user/logout')
  }
}, (error) => {
  console.log(error)
  // 处理 token 超时问题
  if (
    error.response.status==401) {
    // token超时
    router.push('/login')
    // store.dispatch('user/logout')
  }
  ElMessage.error(error.response.statusText) // 提示错误信息
  return Promise.reject(error)
}
)

function getTestICode() {
  const now = Date.now() / 1000
  const code = now + 'LGD_Sunday-1991-12-30'
  return {
    icode: md5(code),
    time: now
  }
}

export default service
