<template>
  <el-menu
    :unique-opened="true"
    :collapse="!store.getters.sidebarOpened"
    :background-color="store.getters.cssVar.menuBg"
    :text-color="store.getters.cssVar.menuText"
    :active-text-color="store.getters.cssVar.menuActiveText"
    :default-active="defaultMenu"
    router
  >
    <SidebarItem
      v-for="item in routes"
      :key="item.path"
      :route="item"
    />

  </el-menu>
</template>

<script setup>
import SidebarItem from "@/layout/components/Sidebar/SidebarItem.vue";
import {useRoute, useRouter} from "vue-router";
import {filterRoute, generateMenus} from "@/utils/route";
import {computed} from "vue";
import {useStore} from "vuex";

const router = useRouter()
const store = useStore()
const routes = computed(() => {
  const fRoute = filterRoute(router.getRoutes())
  return generateMenus(fRoute)
})

// 默认激活菜单
const route = useRoute()
const defaultMenu = computed(() => {
  const {path} = route
  // console.log('path', path)
  return path
})

function handleOpen() {

}

function handleClose() {

}

</script>

<style lang="scss" scoped>

</style>
